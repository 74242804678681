import request from 'umi-request'
import Cookies from 'js-cookie'
import { store } from '../../../store/'
import { authService } from '../ServiceAuth'
import { toast } from 'react-semantic-toasts'

function getLanguageFromRedux() {
    const newState = store.getState()

    return newState.language
}

store.subscribe(getLanguageFromRedux)

const criticalErrorAlert = () => {
    toast({
        type: 'error',
        icon: 'warning',
        title: 'Critical Error',
        description: 'Service unavailable.',
        animation: 'bounce',
        time: 3000,
    })
}

const networkErrorAlert = () => {
    toast({
        type: 'error',
        icon: 'warning',
        title: 'Network Error',
        description: 'You lost internet connection.',
        animation: 'bounce',
        time: 3000,
    })
}

const permissionErrorAlert = () => {
    toast({
        type: 'error',
        icon: 'warning',
        title: 'Missing permissions',
        description: "You can't preform this action.",
        animation: 'bounce',
        time: 3000,
    })
}

const unAuthorizedErrorAlert = () => {
    toast({
        type: 'error',
        icon: 'warning',
        title: 'Session expired',
        description: 'Looks like your login session expired.',
        animation: 'bounce',
        time: 3000,
    })
}

export function queryParams(location) {
    return new URLSearchParams(location?.search)
}

export async function handlePDFGeneration(method, endpoint) {
    let language = getLanguageFromRedux()
    language = language === 'cz' ? 'cs' : language

    let accessToken = Cookies.get('accessToken')
    var xhr = new XMLHttpRequest()
    xhr.open(method, endpoint, true)
    xhr.responseType = 'blob'
    xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8')
    xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
    xhr.setRequestHeader('Accept-Language', language || 'en-us')

    xhr.onload = function (e) {
        if (this.status === 200) {
            var blob = new Blob([this.response], { type: 'application/pdf' }),
                fileURL = URL.createObjectURL(blob)
            window.open(fileURL, '_blank')
        }
    }

    xhr.send()
}

export async function fileDownload(method, endpoint, type, filename, body) {
    return new Promise(function (resolve, reject) {
        let language = getLanguageFromRedux()
        language = language === 'cz' ? 'cs' : language
        let accessToken = Cookies.get('accessToken')
        var xhr = new XMLHttpRequest()
        xhr.open(method, endpoint, true)
        xhr.responseType = 'blob'
        xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8')
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
        xhr.setRequestHeader('Accept-Language', language || 'en-us')

        xhr.onload = function (e) {
            if (this.status === 200) {
                var blob = new Blob([this.response], { type: type }),
                    fileURL = URL.createObjectURL(blob)

                const link = document.createElement('a')
                link.href = fileURL
                if (type !== 'application/pdf') {
                    link.setAttribute('download', filename)
                } else {
                    link.setAttribute('target', '_blank')
                }

                // Append to html link element page
                document.body.appendChild(link)

                // Start download
                link.click()

                // Clean up and remove the link
                link.parentNode.removeChild(link)

                resolve({ status: 200, response: null })
            } else {
                reject({ message: 'Something went wrong. Please try again later.' })
            }
        }

        if (body === undefined) {
            xhr.send()
        } else {
            xhr.send(JSON.stringify(body))
        }

        return xhr
    })
}

async function APICall(config, accessToken) {
    let language = getLanguageFromRedux()
    language = language === 'cz' ? 'cs' : language

    let headers = {}
    if (config.private) {
        // const accessToken = await authService.getAccessToken();
        if (config?.authorization) {
            headers = {
                Authorization: config?.authorization,
                'Accept-Language': config.language || language,
            }
        } else {
            headers = {
                Authorization: `Bearer ${accessToken}`,
                'Accept-Language': config.language || language,
            }
        }
    } else {
        headers = {
            'Accept-Language': config.language || language,
        }
    }

    return await request(config.url, {
        method: config.method,
        data: config.data,
        getResponse: true,
        headers: headers,
    })
        .then((response) => {
            return {
                status: response.response.status,
                response: response.data,
            }
        })
        .catch((error) => {
            if (error.response) {
                if (error.response.status >= 500) {
                    criticalErrorAlert()
                }
                /*if (error.response.status === 403) {
                    permissionErrorAlert()
                }
                if (error.response.status === 401) {
                    unAuthorizedErrorAlert()
                }*/
                return {
                    status: error.response.status,
                    response: error.data,
                }
            } else {
                if (error.name === 'TypeError' && error.message.includes('Failed to fetch')) {
                    networkErrorAlert()
                }
                return {
                    status: 500,
                    response: null,
                }
            }
        })
}

/*
    Example USAGE:

    async function getUserProfile(){
        return await APIRequest({
            url: .../login/,  // API.LOGIN
            method: 'POST', // POST, PUT, PATCH, DELETE
            data: {
                "username": "admin",
                "password": "admin"
            },
            private: false // by default APIRequest is Private, if you want to use public use false value
        });
    }

    Response on Success:
    {
        status: 200, // or other success codes
        response: {...} // as data response object that could contain data or other type of information
    }

    Response on Failure:
    {
        status: 400, // or other failure codes
        response: {...} // as data response object, that could contain error messages
    }

*/

export async function APIRequest(config) {
    /*
     ** Function is responsible for calling and API using specific configuration: URL, Method and Data Object.
     ** Function is Calling private API which means that we need to send API Access Token that is valid.
     */
    let accessToken = Cookies.get('accessToken')
    let refreshToken = Cookies.get('refreshToken')
    let result = {}
    if ((accessToken || config?.authorization) && config.private) {
        if (config?.authorization) {
            result = await APICall(config, null)

            if (result?.status === 401) {
                console.log('Unauthorized')
            }
        } else {
            result = await APICall(config, accessToken)

            // attempt to refresh on unathorized
            if (result?.status === 401) {
                if (refreshToken) {
                    const refreshResult = await authService.refreshLogin(refreshToken)
                    if (refreshResult) {
                        accessToken = Cookies.get('accessToken')
                        refreshToken = Cookies.get('refreshToken')

                        // retry call:
                        result = await APICall(config, accessToken)
                    }
                } else {
                    console.log('Unauthorized')
                    // window.location.href = routes.LOGIN
                }
            }
        }
    } else {
        result = await APICall(config, accessToken)
    }

    return result
}

export const URLManager = {
    url_with_id,
}

function url_with_id(endpoint_prefix, id) {
    return endpoint_prefix + id + '/'
}

export const requests = { get, post, put, patch, del }

async function get(endpoint, isPrivate, customAuthorization) {
    customAuthorization = customAuthorization || null
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: endpoint,
        method: 'GET',
        private: isPrivate,
        authorization: customAuthorization,
    })
}

async function post(endpoint, data, isPrivate, customAuthorization) {
    customAuthorization = customAuthorization || null
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: endpoint,
        method: 'POST',
        data: data,
        private: isPrivate,
        authorization: customAuthorization,
    })
}

async function put(endpoint, data, isPrivate, customAuthorization) {
    customAuthorization = customAuthorization || null
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: endpoint,
        method: 'PUT',
        data: data,
        private: isPrivate,
        authorization: customAuthorization,
    })
}

async function patch(endpoint, data, isPrivate, customAuthorization) {
    customAuthorization = customAuthorization || null
    if (isPrivate === undefined) {
        isPrivate = true
    }

    return await APIRequest({
        url: endpoint,
        method: 'PATCH',
        data: data,
        private: isPrivate,
        authorization: customAuthorization,
    })
}

async function del(endpoint, isPrivate, customAuthorization) {
    customAuthorization = customAuthorization || null
    isPrivate = isPrivate || true

    return await APIRequest({
        url: endpoint,
        method: 'DELETE',
        private: isPrivate,
        authorization: customAuthorization,
    })
}
