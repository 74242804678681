import React, { Fragment } from 'react'

function Loading() {
    return (
        <Fragment>
            <div className="loading">
                <div className="loader">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </Fragment>
    )
}

export default Loading
