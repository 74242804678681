import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
// store
import { routes } from '@store/routes/index'
import { useHasPermission } from '@helpers/hooks'
import { authService } from '@store/services/ServiceAuth'
// components
import SpinnerSegment from '@components/SpinnerSegment'

const PublicRoute = ({ component: Component, restricted, isFullPage, ...rest }) => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [currentPath] = useState(window.location.pathname)
    const user = useSelector((state) => state?.user)

    const canAccessWarehousing = useHasPermission('users.c_terminal_warehouse')

    useEffect(() => {
        async function checkUser() {
            if (restricted) {
                const result = await authService.validateToken()
                setIsLoading(false)

                if (result !== false && restricted) {
                    if (user?.role !== 5) {
                        history.push(routes.DASHBOARD)
                    } else {
                        if (canAccessWarehousing) {
                            history.push(routes.WAREHOUSE_TRACKER)
                        } else {
                            history.push(routes.ATTENDANCE_TRACKER)
                        }
                    }
                }
            } else {
                setIsLoading(false)
            }
        }

        checkUser()
        // eslint-disable-next-line
    }, [history, currentPath, restricted])

    if (isLoading) {
        return <SpinnerSegment loading={true} fullHeight={isFullPage} />
    } else {
        return <Route {...rest} render={(props) => <Component {...props} />} />
    }
}

export default PublicRoute
