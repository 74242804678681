import React from 'react'
import { Container, Grid, Header, Icon } from 'semantic-ui-react'

const SomethingWentWrong = () => {
    return (
        <Container>
            <Grid verticalAlign="middle" centered style={{ height: '100vh' }}>
                <Grid.Row>
                    <Grid.Column style={{ textAlign: 'center' }}>
                        <Icon size="huge" name="warning circle" style={{ width: '100%', color: 'var(--dark)' }} />
                        <Header as="h2">Oops! Application crashed.</Header>
                        <p>Sorry, something went wrong. We're working on fixing it as soon as possible.</p>

                        <button
                            onClick={() => window.location.reload(true)}
                            style={{
                                marginTop: '1rem',
                                cursor: 'pointer',
                                background: 'black',
                                color: 'white',
                                padding: '1rem',
                                border: 'none',
                                borderRadius: '0.5rem',
                            }}
                        >
                            Načítať znovu
                        </button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default SomethingWentWrong
