// react
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { createBrowserHistory } from 'history'
import * as serviceWorker from './serviceWorker'
// store
import { persistor, store } from '@store'
// components
import App from './App'
import Loading from '@components/general/Loading'
import UpdateDialog from '@components/UpdateDialog'
import { ErrorBoundary } from 'react-error-boundary'
import SomethingWentWrong from '@components/SomethingWentWrong'

const domain = window.location.hostname
const origin = window.location.origin
if (origin !== 'http://localhost:3000' && origin !== 'http://127.0.0.1:3000') {
    Sentry.init({
        environment: domain,
        dsn: 'https://54f16442d9f347b6b64613f755b47598@o1354194.ingest.sentry.io/6637604',
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    })
}

const history = createBrowserHistory()

const root = createRoot(document.getElementById('root'))

root.render(
    <ErrorBoundary FallbackComponent={React.memo(SomethingWentWrong)}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router history={history}>
                    <Suspense fallback={<Loading />}>
                        <UpdateDialog />
                        <App />
                    </Suspense>
                </Router>
            </PersistGate>
        </Provider>
    </ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
