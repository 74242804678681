import React, { Suspense, useEffect, lazy } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { SemanticToastContainer } from 'react-semantic-toasts'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
// store
import '@store/i18n'
import { routes } from '@store/routes/index'
// styles
import 'react-semantic-toasts/styles/react-semantic-alert.css'
import '@styles/default.css'
import '@styles/themes.css'
import GlobalStyle from '@styles/globalStyles'
import 'semantic-ui-css/semantic.min.css'
// components
import PrivateRoute from '@components/routes/PrivateRoute'
import PublicRoute from '@components/routes/PublicRoute'
import SpinnerSegment from '@components/SpinnerSegment'
import Error from '@public/Error'

// lazy loaded components
const Home = lazy(() => import('@public/Home'))
const Login = lazy(() => import('@public/auth/Login'))
const Activation = lazy(() => import('@public/auth/Activation'))
const ResetPassword = lazy(() => import('@public/auth/ResetPassword'))
const ConfirmResetPassword = lazy(() => import('@public/auth/ConfirmResetPassword'))
const GDPRView = lazy(() => import('@public/gdprs/GDPRView'))
const GDPRAgreementView = lazy(() => import('@public/gdprs/GDPRAgreementView'))
const PublicApplyForm = lazy(() => import('@dashboard/hr/jobs/views/PublicApplyForm'))
const Dashboard = lazy(() => import('@dashboard/Dashboard'))
const AttendanceTracker = lazy(() => import('@dashboard/trackers/attendance'))
const WarehouseTracker = lazy(() => import('@dashboard/trackers/warehousing'))

const App = () => {
    const { i18n, t } = useTranslation()
    const language = useSelector((state) => state.language)
    const theme = useSelector((state) => state.global_pref)
    const mode = useSelector((state) => state?.theme || 'light')
    const user = useSelector((state) => state.user)

    // Setup initial app state
    useEffect(() => {
        // set initial language
        i18n.changeLanguage(language)

        // set theme (light or dark)
        document.body.classList = ''
        document.body.classList.add(mode)

        // set pendo tracker
        if (user?.id) {
            window.pendo.initialize({
                visitor: {
                    id: user.username,
                    email: user.email,
                    role: user.role,
                    role_name: user.role_name,
                    location: window.location.href,
                    language: language,
                },

                account: {
                    id: window.location.hostname,
                },
            })
        }

        // eslint-disable-next-line
    }, [])

    return (
        <Suspense fallback={<SpinnerSegment loading={true} loadingMessage={t('loading_resources')} fullHeight />}>
            <GlobalStyle theme={theme} />
            <Switch>
                <PrivateRoute path="/dashboard/" component={Dashboard} isFullPage={true} />
                <PrivateRoute path="/warehousing/" component={WarehouseTracker} isFullPage={true} />
                <PrivateRoute path="/attendance/" component={AttendanceTracker} isFullPage={true} />
                <PublicRoute exact restricted={false} path="/" component={Home} isFullPage={true} />
                <PublicRoute
                    exact
                    restricted={false}
                    path={routes.GDPRS + ':id'}
                    component={GDPRView}
                    isFullPage={true}
                />
                <PublicRoute
                    exact
                    restricted={false}
                    path={routes.GDPRS + 'agreement/:uuid'}
                    component={GDPRAgreementView}
                    isFullPage={true}
                />
                <PublicRoute exact restricted={true} path="/login/" component={Login} isFullPage={true} />
                <PublicRoute
                    exact
                    restricted={false}
                    path={routes.PUBLIC_APPLICATION_FORM + ':id'}
                    component={PublicApplyForm}
                    isFullPage={true}
                />
                <PublicRoute
                    exact
                    restricted={false}
                    path="/activate/:uid/:token"
                    component={Activation}
                    isFullPage={true}
                />
                <PublicRoute
                    exact
                    restricted={false}
                    path="/password-reset"
                    component={ResetPassword}
                    isFullPage={true}
                />
                <PublicRoute
                    exact
                    restricted={false}
                    path="/password/reset/confirm/:uid/:token"
                    component={ConfirmResetPassword}
                    isFullPage={true}
                />
                <PublicRoute
                    restricted={false}
                    exact
                    path="*"
                    component={() => <Error code="404" message="Page not found, Sorry!" />}
                    isFullPage={true}
                />
            </Switch>
            <SemanticToastContainer />
        </Suspense>
    )
}

export default withRouter(App)
