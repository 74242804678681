import React, { useEffect, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'
// store
import { routes } from '@routes'
import { authService } from '@services/ServiceAuth'
// components
import CanAccess from '@components/perms/CanAccess'
import SpinnerSegment from '@components/SpinnerSegment'

const PrivateRoute = ({ component: Component, modules, isFullPage, ...rest }) => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const [currentPath, setCurrentPath] = useState(window.location.pathname)

    if (currentPath !== window.location.pathname) {
        setCurrentPath(window.location.pathname)
    }

    useEffect(() => {
        async function checkUser() {
            const result = await authService.validateToken()
            setIsLoading(false)

            if (result === false) {
                history.push(routes.LOGIN + `?next=${currentPath}`)
            }
        }

        checkUser()
    }, [history, currentPath])

    return (
        <>
            {!isLoading ? (
                <CanAccess allowedModules={modules} redirect>
                    <Route {...rest} render={(props) => <Component {...props} />} />
                </CanAccess>
            ) : (
                <SpinnerSegment loading={true} fullHeight={isFullPage} />
            )}
        </>
    )
}

export default PrivateRoute
