import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import packageJson from '../../package.json'
import moment from 'moment'

const buildDateGreaterThan = (latestDate, currentDate) => {
    const momLatestDateTime = moment(latestDate)
    const momCurrentDateTime = moment(currentDate)

    if (momLatestDateTime.isAfter(momCurrentDateTime)) {
        return true
    } else {
        return false
    }
}

const UpdateDialog = () => {
    const { t } = useTranslation()
    const [showUpdateDialog, setShowUpdateDialog] = useState(false)

    useEffect(() => {
        const checkInterval = 60 * 1000 // 1 minute

        const checkForUpdates = () => {
            fetch('/meta.json?date=' + new Date().getTime())
                .then((response) => {
                    if (!response.ok) {
                        console.log('Failed to fetch meta.json')
                    }
                    return response.json()
                })
                .then((meta) => {
                    if (!meta.buildDate) {
                        console.log('Invalid meta.json format')
                    }

                    const latestVersionDate = meta.buildDate
                    const currentVersionDate = packageJson.buildDate

                    const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate)
                    if (shouldForceRefresh) {
                        setShowUpdateDialog(true)
                        //refreshCacheAndReload()
                    } else {
                        setShowUpdateDialog(false)
                    }
                })
                .catch((error) => {
                    console.error('Error fetching update info:', error)
                })
        }

        checkForUpdates() // Call on component mount
        const intervalId = setInterval(checkForUpdates, checkInterval)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const refreshCacheAndReload = () => {
        try {
            if (caches) {
                caches.keys().then((names) => {
                    for (const name of names) {
                        caches.delete(name)
                    }
                })
            }

            setShowUpdateDialog(false)
            window.location.reload(true)
        } catch (error) {
            console.error('Error refreshing cache and reloading:', error)
        }
    }

    return (
        <>
            {showUpdateDialog && (
                <div className="toast-notification">
                    <div className="toast-content">
                        <h4>{t('update_available')}!</h4>
                        <p>{t('please_reload_the_page_to_get_the_latest_version')}</p>
                        <button onClick={() => refreshCacheAndReload()}>{t('confirm')}</button>
                    </div>
                </div>
            )}
        </>
    )
}

export default UpdateDialog
