const addPreferedDashboardReducer = (preferedDashboard = '0', action) => {
    switch (action.type) {
        case 'ADD_PREFERED_DASHBOARD':
            return action.payload
        default:
            return preferedDashboard
    }
}

export default addPreferedDashboardReducer
