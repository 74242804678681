import React from 'react'
import { useTranslation } from 'react-i18next'
// components
import { Segment } from 'semantic-ui-react'

const SpinnerSegment = ({ loading, loadingMessage, children, marginBottom, fullHeight }) => {
    const { t } = useTranslation()

    let styles = {
        padding: 0,
        margin: 0,
        background: 'transparent',
        minHeight: 'auto',
        marginBottom: marginBottom || '1rem',
        border: 'none',
        boxShadow: 'none',
    }

    if (fullHeight) {
        styles.display = 'flex'
        styles.flexDirection = 'column'
        styles.alignItems = 'center'
        styles.justifyContent = 'center'
        styles.minHeight = '100vh'
    }

    return (
        <>
            {loading && (
                <Segment loading={loading} style={styles}>
                    <p style={{ textAlign: 'center', color: 'var(--dark)', paddingTop: '8rem' }}>
                        {loadingMessage || t('loading')}
                    </p>
                </Segment>
            )}
            {!loading && children}
        </>
    )
}

export default SpinnerSegment
